import { createGlobalStyle } from 'styled-components';
import { GlobalBackgroundColors, GlobalFonts, GlobalColors } from "./GlobalStyles";

export const GlobalStylePopupbox = createGlobalStyle`
  
  .negrita{
    font: 22px ${GlobalFonts.fontMedium};
    color: ${GlobalColors.colorTertiary};  
  }
  
  .popupbox-wrapper{
    background-color: ${GlobalBackgroundColors.bgPrimary} !important;
  }
  .popupbox-titleBar{
    color: ${GlobalColors.colorTertiary} !important;
  }
  .popupbox-btn--close {
    font: 24px ${GlobalFonts.fontMedium};
    color: ${GlobalColors.colorTertiary} !important;
    cursor: pointer;
  }
  .popupbox-content {
    padding: 0 !important;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .popupbox-img-width {
      width: 300px !important;
    }
    .popupbox-img-height {
      height: 500px !important;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .popupbox-img-width {
      width: 600px !important;
    }
    .popupbox-img-height {
      height: 700px !important;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .popupbox-img-width {
      width: 900px !important;
    }
    .popupbox-img-height {
      height: 700px !important;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .popupbox-img-width {
      width: 800px !important;
    }
    .popupbox-img-height {
      height: 550px !important;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .popupbox-img-width {
      width: 1000px !important;
    }
    .popupbox-img-height {
      height: 800px !important;
    }
  }
  @media(min-width: 1920px){
    .popupbox-img-width {
      width: 1200px !important;
    }
    .popupbox-img-height {
      height: 900px !important;
    }
  }
  
`
